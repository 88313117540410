.mode-select {
    position: relative;
    z-index: 1;
    display: flex;
    margin: 20px;

    &__select {
        @include border-radius-single(0);
        display: block;
        margin-left: auto;
        padding: 0 30px 0 0;
        color: var(--color-white);
        outline: none;
        border: none;
        background: transparent;
    }
}