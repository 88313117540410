.program-modal {
    position: fixed;
    z-index: 10;
    inset: 0;
    overflow: auto;
    display: none;
    -webkit-overflow-scrolling: touch;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);

    &.open {
        display: block;
    }

    &__inner {
        position: relative;
        box-sizing: border-box;
        display: flex;
        overflow: auto;
        width: 100%;
        margin-inline: auto;
        padding: 40px 20px;
    }

    &__close-btn {
        @include border-radius-single(0);
        position: sticky;
        z-index: 1;
        top: 20px;
        right: 20px;
        margin-left: auto;
        font-size: 0;
        line-height: 0;
        display: block;
        width: 35px;
        height: 35px;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        outline: none;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: '';
            width: 1px;
            height: 100%;
            margin-left: 17px;
            background: var(--color-white);
        }

        &:before {
            @include transforms(rotate(-45deg));
        }

        &:after {
            @include transforms(rotate(45deg));
        }
    }

    &__days {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 60px;
        max-width: 220px;
        margin-inline: auto;
        color: var(--color-white);
    }

    .day {
        &__title {
            display: block;
            font-size: 1.3rem;
            line-height: 1;
            font-weight: bold;
            text-align: center;
        }

        &__timeblocks {
            display: flex;
            flex-direction: column;
            gap: 7px;
            margin-top: 16px;
        }

        &__timeblock {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding: 10px;
            appearance: none;
            color: var(--color-black);
            font-size: 1rem;
            line-height: 1;
            font-weight: bold;
            cursor: pointer;
            border-radius: 10px;
            border: 0;
            background: var(--color-green);

            &:hover {
                background: var(--color-green-hover);
            }

            .time {
                display: block;
            }

            .temperature {
                display: block;
                font-weight: bold;
            }
        }

        &__add-time {
            display: block;
            margin-inline: auto;
            padding: 9px 10px 11px;
            color: var(--color-grey-1);
            font-size: 1rem;
            line-height: 1;
            appearance: none;
            outline: none;
            cursor: pointer;
            border: 0;
            outline: none;
            background: transparent;
            border-radius: 10px;
            background: #1f1f1f;

            &:hover {
                background: #2b2b2b;
            }
        }
    }

    .day__timeblock ~ .day__add-time {
        margin-top: 4px;
    }

    &__add-timeblock {
        width: 100%;
        color: var(--color-white);

        .day {
            display: block;
            margin-bottom: 40px;
            font-size: 2rem;
            line-height: 1;
            font-weight: bold;
            text-align: center;
        }

        .title {
            display: block;
            margin-bottom: 16px;
            font-size: 1.3rem;
            line-height: 1;
            font-weight: bold;
            text-align: center;
        }

        .time-select {
            margin-bottom: 50px;

            &__clock {
                display: flex;
                gap: 10px;
                justify-content: center;

                span {
                    font-size: 1.3rem;

                    &.time {
                        font-weight: bold;    
                    }
                }
            }

            &__range {
                margin-top: 20px;

                .range-slider .range-slider__thumb {
                    background: var(--color-white);
                }

                .range-slider .range-slider__range {
                    background: var(--color-green);
                }
            }
        }

        .temperature-rows {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 5px;
            margin-bottom: 50px;

            button {
                justify-content: center;
                color: var(--color-white);
                background: #2b2b2b;

                &.active {
                    color: var(--color-black);
                    background: var(--color-green);
                }
            }
        }

        .save-row {
            display: block;
            margin-top: 50px;
            margin-inline: auto;
            padding: 9px 15px 11px;
            appearance: none;
            outline: none;
            cursor: pointer;
            font-weight: bold;
            color: var(--color-black);
            background: var(--color-white);
            border: 0;
            border-radius: 10px;
        }

        .delete-row {
            display: block;
            margin-top: 25px;
            margin-inline: auto;
            padding: 5px 13px 7px;
            appearance: none;
            outline: none;
            cursor: pointer;
            font-weight: bold;
            color: var(--color-white);
            background: var(--color-red);
            border: 0;
            border-radius: 10px;
        }
    }
}