@use 'sass:math';

.temp-display {
    @include transforms(translateX(-50%) translateY(-50%));
    position: absolute;
    z-index: 0;
    top: 50%;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    width: 300px;
    height: 300px;
    margin-top: 10px;
    text-align: center;
    color: var(--color-white);

    &__heater-on {
        @include transforms(translateX(-50%));
        position: absolute;
        top: 58px;
        left: 50%;
        max-width: 18px;

        svg {
            width: 100%;
            height: auto;

            .cls-1 {
                @extend %default-transition;
                opacity: 0.2;
                fill: var(--color-grey-1);
            }
        }

        &.on {
            svg {
                .cls-1 {
                    opacity: 1;
                    fill: var(--color-red);
                }
            }
        }
    }

    &__label {
        @extend %default-font-bold;
        @include transforms(translateX(-50%) translateY(-50%));
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 100%;
        margin-top: -41px;
        font-size: math.div(14, $base-font-size) + 0rem;
        text-transform: uppercase;
        opacity: 0.5;
        letter-spacing: 2px;

        // &.program {
        //     display: none;
        // }
    }

    &__current {
        @include transforms(translateX(-50%) translateY(-50%));
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;

        // &.program {
        //     display: none;
        // }

        .main {
            @extend %default-font-bold;
            display: block;
            font-size: math.div(60, $base-font-size) + 0rem;
        }

        .sub {
            @extend %default-font-bold;
            position: absolute;
            top: 13px;
            right: -15px;
            font-size: math.div(20, $base-font-size) + 0rem;
        }
    }

    &__humidity {
        @include transforms(translateX(-50%) translateY(-50%));
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin-top: 52px;
        padding-left: 32px;
        opacity: 0.5;

        &:before {
            @include transforms(translateY(-50%));
            position: absolute;
            top: 50%;
            bottom: 0;
            left: 0;
            display: block;
            content: '';
            width: 25px;
            height: 24px;
            background: url('/assets/images/humidity-icon.svg') no-repeat center center transparent;
            background-size: 18px;
        }
    }

    &__dial-wrapper {
        @include transforms(translateX(-50%) translateY(-50%) rotate(-130deg));
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform-origin: center center;
    }

    &__dial {
        @include transforms(translateX(-50%) translateY(-50%));
        @include border-radius-single(50%);
        position: absolute;
        top: 56%;
        left: 50%;
        width: 80%;
        padding-top: 80%;
        cursor: grab;
        border: 10px solid rgba(255, 255, 255, 0.08);

        &:before {
            @include transforms(translateX(-50%));
            position: absolute;
            top: -18px;
            left: 50%;
            display: block;
            content: '';
            width: 2px;
            height: 18px;
            background: var(--color-red);
        }
    }


    @media (min-width: 400px) {
        width: 350px;
        height: 350px;

        &__heater-on {
            top: 60px;
        }

        &__label {
            margin-top: -52px;
            font-size: math.div(16, $base-font-size) + 0rem;
        }

        &__current {
            .main {
                font-size: math.div(90, $base-font-size) + 0rem;
            }

            .sub {
                top: 20px;
                right: -20px;
                font-size: math.div(30, $base-font-size) + 0rem;
            }
        }

        &__humidity {
            margin-top: 73px;
        }

        &__dial {
            width: 90%;
            padding-top: 90%;
            border: 14px solid rgba(255, 255, 255, 0.08);
        }
    }
}