// Combine multiple transforms
@mixin transforms($transforms) {
    transform: $transforms;
}

// Transform origin
@mixin transform-origin($origin) {
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

// Translate
@mixin translate($x, $y) {
    @include transforms(translateX($x) translateY($y));
}