.login-page {
    position: fixed;
    z-index: 25;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;

    &__inner {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &__form {
        @include transforms(translateX(-50%) translateY(-50%));
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        max-width: 200px;

        .form-wrapper {
            @extend %default-transition;
        }

        &.loading .form-wrapper {
            pointer-events: none;
            opacity: 0.5;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }

        .btn {
            width: 100%;
        }
    }

    &__loading {
        margin-top: 15px;
        color: white;
        text-align: center;
    }
}