@use 'sass:math';

.current-mode {
    text-align: center;
    line-height: 1;

    &__text {
        @include border-radius-single(20px);
        display: inline-block;
        margin: 40px 0 0;
        padding: 8px 20px 10px;
        color: var(--color-white);
        font-size: math.div(15, $base-font-size) + 0rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: none;
        outline: none;
        cursor: pointer;
        background: #242427;
        -webkit-tap-highlight-color: transparent;
    }

    &.changed &__text {
        animation: pulse 0.8s;
    }
}


@keyframes pulse {
    0% {
        @include transforms(scale(1));
    }

    25% {
        @include transforms(scale(1.08));
        background-color: var(--color-red);
    }

    50% {
        @include transforms(scale(1));
    }

    75% {
        @include transforms(scale(1.08));
        background-color: var(--color-red);
    }

    100% {
        @include transforms(scale(1));
    }
}