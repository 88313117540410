.input {
    @extend %default-font;
    @include border-radius-single(0);
    display: block;
    box-sizing: border-box;
    padding: 9px 13px;
    color: var(--color-main-text);
    font-size: 1em;
    line-height: normal;
    outline: none;
    border: 0;

    &::-webkit-input-placeholder {
        color: var(--color-main-text);
    }

    &:-moz-placeholder {
        color: var(--color-main-text);
    }

    &::-moz-placeholder {
        color: var(--color-main-text);
    }

    &:-ms-input-placeholder {
        color: var(--color-main-text);
    }
}
