// Global colors
:root {
    --color-black: #212121;
    --color-white: #ffffff;

    --color-main-text: var(--color-black);

    --color-green: #ade486;
    --color-green-hover: #b4ea8d;
    --color-red: #ff4d5a;
    --color-grey-1: #eee;
}
