@use 'sass:math';

.mode-modal {
    position: fixed;
    z-index: 10;
    inset: 0;
    display: none;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);

    &.open {
        display: block;
    }

    &__inner {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    &__mode {
        display: block;
        text-align: center;

        button {
            @include border-radius-single(20px);
            display: inline-block;
            padding: 8px 20px 10px;
            color: var(--color-white);
            font-size: math.div(15, $base-font-size) + 0rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            border: none;
            outline: none;
            cursor: pointer;
            background: #242427;
            -webkit-tap-highlight-color: transparent;
        }

        &.current button {
            color: var(--color-black);
            background: rgba(255, 255, 255, 0.9);
        }
    }
}