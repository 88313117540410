html,
body {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

html {
    font-size: $base-font-size + 0px;
}

body {
    @extend %default-font;
    color: var(--color-main-text);
    -webkit-font-smoothing: antialiased;
    background: #000;
    user-select: none;
}

a {
    color: inherit;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

strong {
    @extend %default-font-bold;
}