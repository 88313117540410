.btn {
    @extend %default-font-bold;
    @extend %default-transition;
    display: inline-block;
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 1rem;
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
    border: 0;
    outline: none;
    color: var(--color-black);
    text-transform: uppercase;
    background: transparent;
    appearance: none;
    background: var(--color-grey-1);
}