.edit-program-btn {
    position: absolute;
    right: 20px;
    bottom: 15px;
    display: inline-block;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    color: white;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
    text-transform: lowercase;
    outline: none;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    background: url('/assets/images/edit-program-icon.svg') no-repeat center center transparent;
    background-size: 30px;
}